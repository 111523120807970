//share facebook, twitter, ga360
(function () {
  $('.__js_socialShare').click(function (e) {
    e.preventDefault();

    var categoryName = $(this).data('category'),
      href = $(this).data('href'),
      $aID = $(this).parents('.__js_stats_wrapper').data('id'),
      ga360Socials;

    if ($aID == null) {
      $aID = 'footer';
    }

    sendStats('share-' + categoryName, 'click', $aID);

    // do 360 mają być inne nazwy
    if (categoryName === 'facebook') {
      window.open(
        href,
        '_blank',
        'top=100, left=320, width=320,height=280,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_FB';
    }
    if (categoryName === 'twitter') {
      window.open(
        href,
        '_blank',
        'top=100, left=400, width=602,height=400,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_Twitter';
    }
    if (categoryName === 'instagram') {
      ga360Socials = 'share_instagram';
    }
    WP.pwp.customAction({
      ga: {
        event: 'site_interaction',
        eventCategory: ga360Socials,
        eventAction: 'click',
      },
    });
  });

  $('.social__btn').on('click', function () {
    $(this).parents('.article--socials').toggleClass('socials--expanded');
  });
})();
