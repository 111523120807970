var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var playerInfoList = [];

$('.player-yt').each(function () {
  playerInfoList.push({
    id: $(this).attr('id'),
    videoId: $(this).attr('data-video-id'),
  });
});

function onYouTubeIframeAPIReady() {
  if (typeof playerInfoList === 'undefined') return;

  for (var i = 0; i < playerInfoList.length; i++) {
    var curplayer = createPlayer(playerInfoList[i]);
    players[i] = curplayer;
  }
}

var players = new Array();

function createPlayer(playerInfo) {
  return new YT.Player(playerInfo.id, {
    videoId: playerInfo.videoId,
    playerVars: { autoplay: 0 },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
}

function onPlayerReady(event) {
  // event.target.playVideo();
  // event.target.stopVideo();
}

function onPlayerStateChange(event) {
  if (window.innerWidth > 768) {
    if (event.target.getPlayerState() == 1) {
      document
        .getElementById(event.target.h.id)
        .closest('.section-wideo')
        .querySelector('.section__desc').style.display = 'none';
    } else {
      document
        .getElementById(event.target.h.id)
        .closest('.section-wideo')
        .querySelector('.section__desc').style.display = 'block';
    }
  }
}

$('.playerA').each(function (i, e) {
  $(this).attr('id', 'player_' + i);
  var url = $(this).attr('data-url');
  var playerInitialized = false;
  var statsQuadrantsPos = {};
  var clipName;
  var clipMid;
  var player = new WP.player({
    url: url,
    target: 'player_' + i,
    adv: false,
    hiderelated: true,
    extendedrelated: false,
    floatingplayer: false,
    autoplay: false,
    forcesound: true,
    extendedfullsizeenabled: false,
  });
  player.on('INIT', function () {
    if (!playerInitialized) {
      playerInitialized = true;
      this.setVolume(0.5);
    }

  });
  player.on('START_MOVIE', function () {
    clipName = player.getClipData().title;
    clipMid = player.getClipData().mid;
    $('.section--head--button').hide();
    sendStats('video', 'view', clipName);
    // console.log("video", "view", clipName);
  });
  player.on('END_MOVIE', function () {
    $('.section--head--button').show();
    sendStats('video', 'end', clipName);
    // console.log("video", "end", clipName);
  });
  player.on('CHANGE_FULLSCREEN', function () {
    // if (document.getElementById(player.id).classList.contains('fullscreen')) {
    //   document
    //     .getElementById(player.id)
    //     .closest('.video--container')
    //     .classList.add('fulscreen-translate');
    //   document
    //     .getElementById(player.id)
    //     .closest('.outer')
    //     .classList.add('fulscreen-index');
    // } else {
    //   document
    //     .getElementById(player.id)
    //     .closest('.video--container')
    //     .classList.remove('fulscreen-translate');
    //   document
    //     .getElementById(player.id)
    //     .closest('.outer')
    //     .classList.remove('fulscreen-index');
    // }
  });
  player.on('UPDATE_POSITION', function (e) {
    var position = player.getPosition();
    statsQuadrants(position);
  });
  var statsQuadrants = function (p) {
    p = Math.round(p * 100);
    if (!statsQuadrantsPos[clipMid]) {
      statsQuadrantsPos[clipMid] = {};
    }
    if (p >= 25 && p < 50) {
      if (!statsQuadrantsPos[clipMid][25]) {
        statsQuadrantsPos[clipMid][25] = 1;
        sendStats('video', 'quarter=25', clipName);
        // console.log("video", "quarter=25", clipName);
      }
    } else if (p >= 50 && p < 75) {
      if (!statsQuadrantsPos[clipMid][50]) {
        statsQuadrantsPos[clipMid][50] = 1;
        sendStats('video', 'quarter=50', clipName);
        // console.log("video", "quarter=50", clipName);
      }
    } else if (p >= 75 && p < 100) {
      if (!statsQuadrantsPos[clipMid][75]) {
        statsQuadrantsPos[clipMid][75] = 1;
        sendStats('video', 'quarter=75', clipName);
        // console.log("video", "quarter=75", clipName);
      }
    }
  };
});

$(document).ready(function() {
  let poster = 'https://getto-scrollytelling-2022.wpcdn.pl/img/poster.jpg';
  $("#intro video").attr('poster', poster);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 100);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 250);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 500);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 750);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 1000);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 1250);
  setTimeout(function() {
    $("#intro video").attr('poster', poster);
  }, 1500);
});

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(window).on('resize scroll', function() {
  $('video').each(function () {
    if (!$(this).isInViewport()) {
      $(this).get(0).pause();
    }
  });
});
