'use strict';

$.extend($.easing, {
  easeOutExpo: function (x, t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  },
  easeInOutExpo: function (x, t, b, c, d) {
    if (t == 0) return b;
    if (t == d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  },
});

//Obiekt zbierający informacje o elementach typu banner, slider, before/after, których statystyki należy wysłać w unload
var statElementCollector = new Object();
var ga360statElementsInit = function (el) {
  alert($(el).data('ga360-id'));
};

var slidePositionVieved = new Object();

function ga360Test() {
  var statElementArray = [];

  for (var pos in statElementCollector) {
    statElementArray.push(statElementCollector[pos]);
  }
  //console.log(statElementArray);
}

var viewData = [];

// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
  var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function () {
  isScrolligFlag = true;
  if (idTS) {
    clearTimeout(idTS);
  }
  idTS = setTimeout(function () {
    idTS = null;
    isScrolligFlag = false;
  }, 450);
});

$('[data-st-click]').bind('click', function () {
  var T = $(this).data('st-click');
  if (T.length) {
    if (T.value.length) {
      sendStats(T.category, T.action, T.label, T.value);
    } else {
      sendStats(T.category, T.action, T.label);
    }
  }
});

if(WP.cookie.get("getto-lang") == 'pl') {
  $('.language a[hreflang="pl"]').parent().addClass('__active');
}
else if(WP.cookie.get("getto-lang") == 'en') {
  $('.language a[hreflang="en"]').parent().addClass('__active');
}

$('.cite__in-text').each(function () {
  let firstChar = $.trim($(this).find('.cite_content h4').text()).charAt(0);
  $(this).find('.cite__text-wrapper').prepend('<div class="first-letter"></div>');
  $(this).find('.first-letter').text(firstChar);
  $(this).find('.cite_content h4').html($(this).find('.cite_content h4').text().replace(firstChar, ''));
});

$('.article__lead').each(function () {
  let firstChar = $.trim($(this).find('.lead_content p').text()).charAt(0);
  $(this).prepend('<div class="first-letter"></div>');
  $(this).find('.first-letter').text(firstChar);
  $(this).find('.lead_content p').html($(this).find('.lead_content p').text().replace(firstChar, ''));
});

//intro
if ($(window).width() < 768) {
  $('#intro > .global--fullpage-section').removeClass('section-fixed');
}
else {
  if(!$('#intro > .global--fullpage-section').hasClass('section-fixed')) {
    $('#intro > .global--fullpage-section').addClass('section-fixed');
  }
}

window.onresize = function() {
  if ($(window).width() < 768) {
    $('#intro > .global--fullpage-section').removeClass('section-fixed');
  }
  else {
    if(!$('#intro > .global--fullpage-section').hasClass('section-fixed')) {
      $('#intro > .global--fullpage-section').addClass('section-fixed');
    }
  }
};
