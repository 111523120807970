const setSize = function () {
  document.documentElement.style.setProperty(
    '--browserArea',
    window.innerHeight + 'px'
  );
};

window.addEventListener('DOMContentLoaded', () => {
  setSize();
});

window.addEventListener('resize', (event) => {
  if (window.innerWidth > 768) {
    setSize();
  }
});

window.addEventListener('orientationchange', (event) => {
  setSize();
});
