const animatedElements = document.querySelectorAll(
  '._js_inViewport, .__js_preload, .section-fixed, .section-wideo, .section-double-fixed'
);

const obsCallback = function (entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('_js_inViewport')) {
        entry.target.classList.add('__js_onScreen');
      }
      if (entry.target.classList.contains('__js_preload')) {
        const srcS = entry.target.getAttribute('data-src');
        $(entry.target)
          .css('opacity', '.3')
          .attr('src', srcS)
          .animate({ opacity: 1 }, 250);
        entry.target.classList.remove('__js_preload');
        observer.unobserve(entry.target);
      }
      if (entry.target.classList.contains('section-fixed')) {
        entry.target
          .querySelector('.img-fixed-bg')
          .classList.add('fullpage-fixed');
      }
      if (entry.target.classList.contains('section-wideo')) {
        entry.target
          .querySelector('.section-article--video-wrapper')
          .classList.add('wideo-fixed');
      }
      if (entry.target.classList.contains('section-double-fixed')) {
        entry.target
          .querySelector('.section__images')
          .classList.add('fullpage-fixed');
      }
    } else {
      if (
        entry.target.classList.contains('_js_inViewport') &&
        entry.target.classList.contains('__js_onScreen')
      ) {
        // entry.target.classList.remove('__js_onScreen');
        observer.unobserve(entry.target);
      }
      if (entry.target.classList.contains('section-fixed')) {
        entry.target
          .querySelector('.img-fixed-bg')
          .classList.remove('fullpage-fixed');
      }
      if (entry.target.classList.contains('section-wideo')) {
        entry.target
          .querySelector('.section-article--video-wrapper')
          .classList.remove('wideo-fixed');
      }
      if (entry.target.classList.contains('section-double-fixed')) {
        entry.target
          .querySelector('.section__images')
          .classList.remove('fullpage-fixed');
      }
    }
  });
};

let windowH = `${(window.innerHeight - 100) * -1}px`;

const observer = new IntersectionObserver(obsCallback, {
  root: null,
  threshold: 0,
  // rootMargin: '-10px',
});

animatedElements.forEach((elem) => {
  observer.observe(elem);
});
